define([
    'lodash',
    'componentsCore',
    'coreUtils',
    'compUtils',
    'create-react-class',
    'wixappsCore',
    'santa-components',
    'mediaRichText/bi/errors',
    'mediaRichText/galleryHelpers/galleryHelpers',
    'mediaRichText/wixMusicEmbedMixin/wixMusicEmbedMixin'
], function (
    _,
    componentsCore,
    coreUtils,
    compUtils,
    createReactClass,
    wixappsCore,
    santaComponents,
    errors,
    galleryHelpers,
    wixMusicEmbedMixin
) {
    'use strict';
    const wixMusicEmbed = createReactClass({
        displayName: 'wixMusicEmbed',
        mixins: [wixMusicEmbedMixin],
        render() {
            return santaComponents.utils.createReactElement('iframe', markWixComp(this.getIframeProps()));
        }
    });

    const fragment = coreUtils.fragment;

    const textUtils = santaComponents.utils.textComponentsUtils;

    const customTagsRenderedToHtmlMap = {
        'wline': 'div'
    };

    function convertToValidHtmlTag(tagName) {
        return customTagsRenderedToHtmlMap[tagName] || tagName;
    }

    function getMeasuredComponentWidth() {
        const measureMap = this.props.siteData.measureMap;
        if (measureMap) {
            this._lastMeasuredWidth = measureMap.width[this.props.id];
        }

        return this._lastMeasuredWidth;
    }

    function _getCompJsonWhichMayThrow(compJsonString, src) {
        const compJson = JSON.parse(compJsonString.replace(/&quot;/g, '"'));
        compJson.url = src;
        return compJson;
    }

    function getCompJsonObject(compJsonString, src) {
        try {
            return _getCompJsonWhichMayThrow(compJsonString, src);
        } catch (e) {
            this.props.siteAPI.reportBI(
                errors.MEDIA_RICH_TEXT_WRONG_COMP_DATA,
                {
                    wixCompJson: compJsonString,
                    errorDesc: e.message,
                    errorStack: e.stack
                }
            );
            return undefined;
        }
    }

    function calcInnerIdFromDataQuery(dataquery) {
        return `innerComp_${dataquery.replace('#', '')}`;
    }

    function setInnerCompCommonStyleDefinitions(props, compJson) {
        const style = props.style;
        style.marginTop = '10px';
        style.marginBottom = '10px';
        style.marginLeft = compJson.marginLeft;
        style.marginRight = compJson.marginRight;
        style.position = 'static';
    }

    function calcInnerComponentStyleByFloatValue(compJson, props) {
        const style = props.style;
        if (compJson.floatValue) {
            style.float = compJson.floatValue;
            style.display = '';
            style.clear = '';
        } else {
            style.display = compJson.display;
            style.clear = 'both';
            style.float = '';
        }
    }

    function addItemPropAttribute(compJson, props) {
        if (_.has(compJson, 'post-cover-photo')) {
            props.addItemProp = true;
        }

        return props;
    }

    function convertImagesToQueriesAndPatchGetDataByQuery(props) {
        const galleryData = _.cloneDeep(props.compData); //otherwise we have circular json
        props.compData.items = _.map(props.compData.items, function (imageData) {
            return _.assign({galleryData}, imageData); //unfortunately, this is how the displayers know to open nonPageItemZoom and which images it can page through
        });
    }

    function hasSingleTextChild(node) {
        return node.childNodes && node.childNodes.length === 1 && node.childNodes[0].nodeType === fragment.Node.TEXT_NODE;
    }

    function markWixComp(props) {
        //we need this to fix CLNT-8798, since the blog data has bad DOM nesting
        return _.assign(props, {wixComp: true});
    }

    function isWixComponent(reactElement) {
        //see: https://facebook.github.io/react/blog/2015/12/18/react-components-elements-and-instances.html#elements-describe-the-tree
        // type: (string | ReactClass) => if its a function, its not a native dom element.
        // We add the wixComp props just in case someone makes a custom comp which is not actually an element we want to reparent
        return _.isFunction(reactElement.type) && reactElement.props.wixComp;
    }

    function withoutHash(str) {
        return str.replace(/^#/, '');
    }

    function shouldRenderAsWixComp(wixCompProp, node) {
        return wixCompProp && !_.has(customTagsRenderedToHtmlMap, node.tagName.toLowerCase());
    }

    /**
     * returns structureInfo shape, to be used later to layout these components
     * https://github.com/wix-private/santa/blob/master/packages/layout/src/main/util/layout.js#L96
     * **/
    function getStructureInfo(props, maxWidth) {
        const layout = _.get(props, 'structure.layout', {});
        if (layout.width) {
            layout.width = Math.min(maxWidth, layout.width);
        }

        return _.assign({}, props.structure, {
            dataItem: props.compData,
            propertiesItem: props.compProp,
            layout,
            id: props.structure.id,
            type: props.structure.componentType,
            structure: props.structure
        });
    }

    function registerComponentForLayout(componentProps) {
        const siteData = this.props.siteData;
        const mediaRichTextCompId = this.props.id;
        const mediaRichTextCalculatedWidth = this._getMediaRichTextComponentWidth();
        const structureInfo = getStructureInfo(componentProps, mediaRichTextCalculatedWidth);
        const componentId = structureInfo.id;

        siteData.registerMediaRichTextInnerComponent(mediaRichTextCompId, componentId, structureInfo);
    }

    function getComponentStructure(compJson) {
        // the compJson is already in "structure" form, but is sometimes missing an id, which is added here
        const id = compJson.id || calcInnerIdFromDataQuery(compJson.dataQuery);
        return _.assign(compJson, {id});
    }

    /**
     * @class components.MediaRichText
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */

    const mediaRichText = {
        displayName: 'MediaRichText',
        mixins: [santaComponents.mixins.textCompMixin],
        propTypes: {
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            getComponentProps: wixappsCore.CoreSantaTypes.getComponentProps.isRequired
        },
        allowIframes: true,
        //used from the textCompMixin
        convertCompDataTextToHTML(nextProps) {
            this.componentDataQueryList = _.map(nextProps.compData.componentDataList || [], withoutHash);

            this._compData = nextProps.compData;

            this._componentHtml = this._componentHtml.replace(/\n|\r|\t/g, '');

            this._componentHtml = textUtils.convertDataQueryLinksIntoHtmlAnchors(this._componentHtml, nextProps.compData.linkList, _.partialRight(coreUtils.linkRenderer.renderLink, nextProps.siteData, nextProps.rootNavigationInfo));
            this._componentHtml = textUtils.mobileTextTransformIfNeeded(
                this._componentHtml,
                {
                    brightness: _.get(nextProps, 'compProp.brightness'),
                    isMobileView: nextProps.siteData.isMobileView(),
                    scale: _.get(nextProps, 'structure.layout.scale'),
                    fontGetter: nextProps.siteData.getFont.bind(nextProps.siteData),
                    colorGetter: nextProps.siteData.getColor.bind(nextProps.siteData)
                }
            );
            this.renderedDataQueries = {};
            this._componentHtml = this._convertComponentsPlaceHoldersToRenderedComponents(this._componentHtml);
            if (_.size(this.renderedDataQueries) !== _.size(this.componentDataQueryList)) {
                _(this.componentDataQueryList)
                    .without(_.keys(this.renderedDataQueries))
                    .forEach(function (dataQuery) {
                        //we have component data query in the data list but we do not have a place holder for it in the text inner html
                        this.props.siteAPI.reportBI(
                            errors.MEDIA_RICH_MISSING_COMPONENT_PLACEHOLDER,
                            {
                                dataQuery: `#${dataQuery}`
                            }
                        );
                    }.bind(this));
            }

            this._registerReLayoutFunc = nextProps.registerReLayout;
        },
        _addToCompDataListIfMissing(dataQuery) {
            const dataQueryWithoutHash = withoutHash(dataQuery);
            if (!_.includes(this.componentDataQueryList, dataQueryWithoutHash)) {
                this.componentDataQueryList.push(dataQueryWithoutHash);
            }
        },

        _convertNodeFragmentToReactElements(node, i) { // eslint-disable-line complexity
            if (node.nodeType === fragment.Node.ELEMENT_NODE) {
                const props = _.reduce(node.attributes, function (acc, attr) {
                    if (attr.name === 'style') {
                        acc.style = _.reduce(attr.value.split(';'), function (styleAcc, styleSegment) {
                            const styleSegmentsParts = styleSegment.split(':');
                            if (styleSegmentsParts.length === 2) {
                                styleAcc[_.camelCase(styleSegmentsParts[0])] = styleSegmentsParts[1];
                            }
                            return styleAcc;
                        }, {});
                    } else if (attr.name === 'class') {
                        acc.className = attr.value;
                    } else if (attr.name === 'frameborder') {
                        acc.frameBorder = attr.value;
                    } else if (_.startsWith(attr.name, 'data-')) {
                        acc[attr.name] = attr.value;
                    } else {
                        acc[_.camelCase(attr.name)] = attr.value;
                    }
                    return acc;
                }, {});
                if (shouldRenderAsWixComp(props.wixComp, node)) {
                    const compJson = getCompJsonObject.call(this, props.wixComp, props.src);
                    if (compJson) {
                        return this._handleInnerComps(compJson, props);
                    }
                    return '';
                }
                const children = hasSingleTextChild(node) ? node.textContent : _.flatMap(node.childNodes, this._convertNodeFragmentToReactElements);
                if (_.isArray(children) && children.length && _.every(children, isWixComponent)) {
                    return children;
                }

                const propsWithChildren = _.includes(this.voidElements, node.tagName.toLowerCase()) ? props : _.assign(props, {children});
                return santaComponents.utils.createReactElement(convertToValidHtmlTag(node.tagName.toLowerCase()),
                    _.defaults(propsWithChildren, {key: `${node.tagName}${i}`}));
            }
            if (node.nodeType === fragment.Node.TEXT_NODE) {
                return node.textContent;
            }
            return '';
        },

        voidElements: ['area', 'base', 'basefont', 'bgsound', 'br',
            'col', 'command', 'embed', 'frame', 'hr', 'image', 'img',
            'input', 'isindex', 'keygen', 'link', 'menuitem', 'meta',
            'nextid', 'param', 'source', 'track', 'wbr'],

        _convertComponentsPlaceHoldersToRenderedComponents(text) {
            const node = fragment.document.createElement('div');
            node.innerHTML = text;
            return _.map(node.childNodes, this._convertNodeFragmentToReactElements);
        },
        _validateAndFixGalleryCompData(defaultBasicProps, compJson) {
            const propsToReturn = _.clone(defaultBasicProps);
            const galleryJson = galleryHelpers.buildGalleryJsonFromCkData(compJson, this._compData.innerCompsData, defaultBasicProps.style);
            _.assign(propsToReturn, galleryJson);

            convertImagesToQueriesAndPatchGetDataByQuery(propsToReturn);
            return propsToReturn;
        },
        _createInnerComponentProperties(compClass, compJson) {
            const props = this._createInnerComponentBasicProperties(compClass, compJson);

            if (compJson.componentType === 'wysiwyg.viewer.components.WPhoto' || compJson.componentType === 'wysiwyg.viewer.components.Video') {
                return addItemPropAttribute(compJson, props);
            } else if (galleryHelpers.isGalleryComponent(compJson.componentType)) {
                return this._validateAndFixGalleryCompData(props, compJson);
            }

            this.props.siteAPI.reportBI(errors.MEDIA_RICH_TEXT_UNSUPPORTED_COMPONENT, {wixCompJson: compJson});
            return props;
        },
        _getMediaRichTextComponentWidth() {
            return getMeasuredComponentWidth.call(this) || _.get(this.props, 'style.width') || 630;//default single-post width
        },
        _createInnerComponentBasicProperties(compClass, compJson) {//eslint-disable-line complexity
            const componentStructure = getComponentStructure(compJson);
            let parentProps;
            const compData = _.get(this._compData.innerCompsData, componentStructure.dataQuery);
            let compProp;
            if (componentStructure.componentType === 'wysiwyg.viewer.components.WPhoto') {
                if (componentStructure.linkDataQuery) {
                    const id = componentStructure.linkDataQuery.slice(1);
                    compData.link = _.find(this._compData.linkList, {id});
                }
                compProp = {
                    displayMode: 'fitWidthStrict'
                };
                parentProps = {compData, compProp};
                parentProps.disableImageAutoLayout = true; // do not use WixImage mechanism for wPhoto in old appParts
            } else if (componentStructure.componentType === 'wysiwyg.viewer.components.Video') {
                compProp = {
                    showControls: 'temp_show',
                    enablejsapi: 1
                };
                parentProps = {compData, compProp};
            }

            const props = this.props.getComponentProps(componentStructure, this.props.rootId, parentProps);

            props.style = props.style || {};
            props.structure = props.structure || {};
            props.key = props.key || compJson.key;

            //when in wixapps, we do not have data query and the comp data is empty
            //the inner components cannot have reference
            props.registerReLayout = this._registerReLayoutFunc || this.registerReLayout;

            //set inner component style
            setInnerCompCommonStyleDefinitions(props, componentStructure);
            calcInnerComponentStyleByFloatValue(componentStructure, props);
            this._calcInnerCompWidthAndHeight(componentStructure, props);

            return props;
        },
        _calcInnerCompWidthAndHeight(compJson, props) {
            const mediaRichTextComponentWidth = this._getMediaRichTextComponentWidth();
            let newWidth = mediaRichTextComponentWidth;
            const originalImageWidth = props.compData && props.compData.width;
            const destWidthInPercentage = compJson.width;

            if (_.isNumber(destWidthInPercentage)) {
                newWidth = this._getWidthMultiplier(compJson) * mediaRichTextComponentWidth;
            } else if (_.isNumber(originalImageWidth)) {
                newWidth = Math.min(originalImageWidth, mediaRichTextComponentWidth);
            }

            props.style.width = newWidth;
            if (compJson.dimsRatio) {
                props.style.height = props.style.width * compJson.dimsRatio;
            } else if (compJson.componentType === 'wysiwyg.viewer.components.Video') {
                //video has to have dimsRatio - sometimes, due to old bug the ratio is missing, use default = 0.5625 (16:9)
                props.style.height = props.style.width * 0.5625;
            }
        },

        /**
         * If in mobile we want the width to always take 100% else what the user chose
         * @param compJson
         * @private
         */
        _getWidthMultiplier(compJson) {
            return this.props.siteData.isMobileView() ? 0.99 : compJson.width;
        },
        _handleInnerComps(compJson, props) {
            if (compJson.componentType === 'htmlComp') {
                return this._handleHtmlComp(compJson, props);
            } else if (compJson.componentType === 'music') {
                return this._handleMusicComp(compJson, props);
            }

            return this._handleInnerSantaComponent(compJson);
        },
        _handleInnerSantaComponent(compJson) {//eslint-disable-line react/display-name
            const innerClass = compUtils.compFactory.getCompReactClass(compJson.componentType);
            const innerProps = this._createInnerComponentProperties(innerClass, compJson);
            this.renderedDataQueries[withoutHash(compJson.dataQuery)] = 1;
            this._addToCompDataListIfMissing(compJson.dataQuery);
            registerComponentForLayout.call(this, innerProps);
            const innerClassFactory = compUtils.compFactory.getCompClass(compJson.componentType, true);

            return innerClassFactory(markWixComp(innerProps));
        },

        _handleHtmlComp(compJson, props) {//eslint-disable-line react/display-name, complexity
            const permUrl = this.props.siteData.serviceTopology.staticHTMLComponentUrl;
            const tempUrl = '//0.htmlcomponentservice.com/';
            let htmlCompUrl;

            function stripProtocolFromUrl(url) {
                return url.replace(/^(https?:)?\/\//, '');
            }

            if (compJson.type === 'website') {
                htmlCompUrl = `//${stripProtocolFromUrl(compJson.websiteUrl)}`;
            } else {
                const baseUrl = compJson.urlStatus === 'temp' ? tempUrl : permUrl;
                htmlCompUrl = baseUrl + compJson.relativeUrl;
            }

            if (compJson.align === 'center') {
                props.style = {display: 'block', clear: 'both', margin: '0 auto'};
            } else {
                props.style = {float: compJson.align};
            }

            props.src = htmlCompUrl; // eslint-disable-line santa/no-side-effects
            props.sandbox = 'allow-scripts allow-same-origin allow-popups'; // eslint-disable-line santa/no-side-effects

            if (this.props.siteData.isMobileView() && compJson.dimsRatio) {
                props.width = '99%';
                if (compJson.dimsRatio > 1) {
                    const calculatedWidth = this._getMediaRichTextComponentWidth() ? parseInt(this._getMediaRichTextComponentWidth(), 10) : 1;
                    const actualHeight = 0.99 * calculatedWidth * compJson.dimsRatio;

                    props.height = actualHeight;
                }
            } else {
                props.width = compJson.width;
            }
            return santaComponents.utils.createReactElement('iframe', markWixComp(props));
        },
        _handleMusicComp(compJson, props) {//eslint-disable-line react/display-name
            const wixMusicEmbedUrl = _.get(this.props.siteData, ['serviceTopology', 'scriptsLocationMap', 'wix-music-embed']);
            const validId = /^[\w\-]+$/i;
            const isPayemntIdValid = validId.test(compJson.paymentId) || compJson.paymentId === '';

            if (!(validId.test(compJson.albumId) && validId.test(compJson.presetId) && isPayemntIdValid)) {
                return;
            }

            props.src = `${wixMusicEmbedUrl}/index.html?albumId=${compJson.albumId // eslint-disable-line santa/no-side-effects
            }&paymentsId=${compJson.paymentId}&presetId=${compJson.presetId}&playerId=${compJson.playerId}&hideDetailedTrackInfo=true`;

            if (this.props.siteData.isMobileView()) {
                props.src += '&isMobile=true';
            }
            props.sandbox = 'allow-scripts allow-same-origin allow-popups'; // eslint-disable-line santa/no-side-effects
            props.playerId = compJson.playerId; // eslint-disable-line santa/no-side-effects
            delete props.wixComp;

            props.width = '100%'; // eslint-disable-line santa/no-side-effects

            return santaComponents.utils.createReactElement(wixMusicEmbed, props);
        },

        componentWillUnmount() {
            const siteData = this.props.siteData;
            siteData.clearMediaRichTextInnerComponents(this.props.id);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.MediaRichText', mediaRichText);

    return mediaRichText;
});
