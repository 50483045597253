define(['lodash'], function (_) {
    'use strict';

    return {
        getInitialState() {
            return {height: this.props.height};
        },
        onPlayerMessage(event) {
            if (event.data.event === 'resizeOfWixMusicIframe' && event.data.playerId === this.props.playerId) {
                this.setState({height: event.data.size.maxHeight});
            }
        },
        componentDidMount() {
            window.addEventListener('message', this.onPlayerMessage);
        },
        componentWillUnmount() {
            window.removeEventListener('message', this.onPlayerMessage);
        },
        getIframeProps() {
            return _.assign({}, this.props, {height: this.state.height});
        }
    };
});
