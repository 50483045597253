define(['mediaRichText/bi/errors.json', 'lodash', 'coreUtils'], function (errors, _, coreUtils) {
    'use strict';

    /**
     * Please ctrl/cmd + click on biError to see the schema :)
     * @type {Object.<String, biError>}
     */

    _.forEach(errors, function (error, key) {
        error.errorName = key; // eslint-disable-line santa/no-side-effects
    });

    coreUtils.loggingUtils.logger.register('mediaRichText', 'error', errors);

    return errors;
});
